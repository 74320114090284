import { AuthType } from "./type";

// Auth intial state
const initialAuthState = {
  user: JSON.parse(localStorage.getItem("spinzyPlayAdminIN"))
    ? JSON.parse(localStorage.getItem("spinzyPlayAdminIN"))
    : null,
  isLoading: false,
  error: null,
  countryCode: "US",
  SoftwareList: null,
  isSoftwareModalLoading: false,
  isVersionLoading: false,
  version: null
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case AuthType.USER_SIGNIN_REQUEST:
      return { ...state, isLoading: true };
    case AuthType.USER_SIGNIN_SUCCESS:
      return { ...state, user: action?.payload?.data, isLoading: false };
    case AuthType.USER_SIGNIN_FAIL:
      return { ...state, isLoading: false, error: action.payload };
    case AuthType.LOG_OUT:
      return { ...state, isLoading: false, user: null };
    case AuthType.AUTH_CHEK_REQUEST:
      return { ...state, isLoading: true };
    case AuthType.AUTH_CHEK_SUCCESS:
      return { ...state, isLoading: false, user: action.payload.data };
    case AuthType.AUTH_CHEK_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case AuthType.REFRESH_CAPTCHA_REQUEST:
      return { ...state, isLoading: true };
    case AuthType.REFRESH_CAPTCHA_SUCCESS:
      return { ...state, isLoading: false };
    case AuthType.REFRESH_CAPTCHA_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case AuthType.CHEK_CAPTCHA_REQUEST:
      return { ...state, isLoading: true };
    case AuthType.CHEK_CAPTCHA_SUCCESS:
      return { ...state, isLoading: false };
    case AuthType.CHEK_CAPTCHA_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case AuthType.PASSWORD_CHANGE_REQUEST:
      return { ...state, isLoading: true };
    case AuthType.PASSWORD_CHANGE_SUCCESS:
      return { ...state, isLoading: false };
    case AuthType.PASSWORD_CHANGE_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case AuthType.GET_PROFILE_REQUEST:
      return { ...state, isLoading: true };
    case AuthType.GET_PROFILE_SUCCESS:
      return { ...state, isLoading: false, user: { ...state.user, ...action.payload.data } };
    case AuthType.GET_PROFILE_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case AuthType.UPDATE_PROFILE_REQUEST:
      return { ...state, isLoading: true };
    case AuthType.UPDATE_PROFILE_SUCCESS:
      return { ...state, isLoading: false };
    case AuthType.UPDATE_PROFILE_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case AuthType.GET_COUNTRY:
      return { ...state, countryCode: action.payload.data };
    case AuthType.GET_SOFTWARE_LIST_REQUEST:
      return { ...state, isSoftwareModalLoading: true };
    case AuthType.GET_SOFTWARE_LIST_SUCCESS:
      return { ...state, isSoftwareModalLoading: false, SoftwareList: action.payload.data };
    case AuthType.GET_SOFTWARE_LIST_FAIL:
      return { ...state, isSoftwareModalLoading: false };
    case AuthType.GET_APPLICATION_VERSION_LIST_REQUEST:
      return { ...state, isVersionLoading: true };
    case AuthType.GET_APPLICATION_VERSION_LIST_SUCCESS:
      return { ...state, isVersionLoading: false, version: action.payload.data };
    case AuthType.GET_APPLICATION_VERSION_LIST_FAIL:
      return { ...state, isVersionLoading: false };
    default:
      return state;
  }
};
